import React, { useEffect, useState } from "react";
import "../CSS/user.css";
import UserService from "../Services/UserService";
import { useServerError } from "../Utils/handleServerError";
import { useDispatch, useSelector } from "react-redux";
import {
  removeUser,
  setSelectedUser,
  setUsers,
  updateUser,
} from "../Redux/slices/usersSlice";
import { toast } from "react-toastify";
import PopModal from "../Components/ModelLayout/ModalLayout";
import ConfirmDeleteUser from "../Components/ConfirmDeleteUser";
import UpdateBalanceModal from "../Components/UpdateBalance";
import { useNavigate } from "react-router-dom";
import ConfirmUpdatePlan from "../Components/ConfirmUpdatePlan";

export const User = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { users } = useSelector((state) => state.usersSlice);

  const [loading, setLoading] = useState(false);

  const { token } = useSelector((state) => state.authSlice);

  const [error, setError] = useState(null);

  useServerError(error);

  function openErrorMessage(error) {
    setError(error);

    setTimeout(() => {
      setError(null);
    }, 1000);
  }

  async function getUsers() {
    if (loading) return;

    setLoading(true);

    try {
      const res = await UserService.get_users(token);

      if (Array.isArray(res.data)) {
        dispatch(setUsers(res.data));
      }
    } catch (e) {
      openErrorMessage(e);
    }

    setLoading(false);
  }

  useEffect(() => {
    getUsers();
  }, []);

  const getTotalDeposit = (deposits) => {
    if (!(deposits instanceof Array)) {
      return 0;
    }

    let total = 0;

    deposits.forEach((e) => (total += e.amount));

    return total;
  };

  const [deleteProps, setDeleteProps] = useState(null);

  const openDeleteModal = (handleOk, handleCancel) => {
    setDeleteProps({ handleOk, handleCancel });
  };

  const closeDeleteModal = () => {
    setDeleteProps(null);
  };

  const confirmDelete = () =>
    new Promise((res, _) => {
      openDeleteModal(
        () => res(true),
        () => {
          res(false);
          closeDeleteModal();
        }
      );
    });

  const handleDelete = async (id) => {
    try {
      const proceed = await confirmDelete();

      if (proceed && token) {
        setLoading(true);

        const res = await UserService.remove_user(token, id);

        if (res.data) {
          dispatch(removeUser(id));
          toast(res.data.message, {
            type: "success",
            theme: "colored",
          });
          closeDeleteModal();
        }
      }
    } catch (error) {
      openErrorMessage(error);
    }

    setLoading(false);
  };

  const [updatePlanProps, setUpdatePlanProps] = useState(null);

  const openUpdatePlanModal = (handleOk, handleCancel) => {
    setUpdatePlanProps({ handleOk, handleCancel });
  };

  const closeUpdatePlanModal = () => {
    setUpdatePlanProps(null);
  };

  const confirmUpdatePlan = () =>
    new Promise((res, _) => {
      openUpdatePlanModal(
        res,
        () => {
          res(false);
          closeUpdatePlanModal();
        }
      );
    });

  const updateCurrentTradePlan = async (id) => {
    setLoading(true);
    try {
      const plan = await confirmUpdatePlan();

      console.log("plan", plan, id);

      if (plan && token) {
        setLoading(true);

        const res = await UserService.update_active_plan(token, id, plan);

        if (res.data) {
          dispatch(
            updateUser({
              user_id: id,
              service_type: plan
            })
          );

          toast(res.data.message, {
            type: "success",
            theme: "colored",
          });

          closeUpdatePlanModal();
        }
      }
    } catch (error) {
      console.log(error);
      openErrorMessage(error);
    }
    setLoading(false);
  };

  const [updateProps, setUpdateProps] = useState(null);

  const goToWithdrawalLog = (id) => () => {
    dispatch(setSelectedUser(id));
    navigate("/users/withdraw-log");
  };

  const goToDepositLog = (id) => () => {
    dispatch(setSelectedUser(id));
    navigate("/users/deposit-log");
  };

  const goToTradeLog = (id) => () => {
    dispatch(setSelectedUser(id));
    navigate("/users/trade-log");
  };

  const handleApprove = async (status, user_id) => {
    try {
      const res = await UserService.setApprovedStatus({status, user_id}, token);
      toast("Approved status updated.", { type: "success", theme: "colored" });
      dispatch(updateUser(res.data));
    } catch (error) {
      
    }
  }

  const openUpdateBalanceModal = (initialAmount, handleSubmit, onClose) => {
    setUpdateProps({ initialAmount, handleSubmit, onClose });
  }

  const closeUpdateBalanceModal = () => setUpdateProps(null);

  const getUpdateBalanceAmount = (initialAmount) => new Promise((res) => {
    openUpdateBalanceModal(initialAmount, res, closeUpdateBalanceModal);
  });

  const handleUpdateBalance = async (balance_type, initialAmount, id) => {
    setLoading(true);    
    try {
      const amount = await getUpdateBalanceAmount(initialAmount);
      const res = await UserService.change_balance(token, { amount: Number(amount), balance_type, id });
      dispatch(
        updateUser({
          user_id: id,
          [balance_type]: Number(amount)
        })
      );

      toast(res.data.message, {
        type: "success",
        theme: "colored",
      });
    } catch (error) {
      console.log(error);
    } finally {
      closeUpdateBalanceModal();
      setLoading(false);
    }
  }

  return (
    <>
      {deleteProps && (
        <PopModal modalToggler={deleteProps !== null} onClose={() => {}}>
          <ConfirmDeleteUser
            handleOk={deleteProps.handleOk}
            handleCancel={deleteProps.handleCancel}
          />
        </PopModal>
      )}

      {updatePlanProps && (
        <PopModal modalToggler={updatePlanProps !== null} onClose={() => {}}>
          <ConfirmUpdatePlan
            handleOk={updatePlanProps.handleOk}
            handleCancel={updatePlanProps.handleCancel}
          />
        </PopModal>
      )}

      {updateProps && (
        <PopModal modalToggler={updateProps !== null} onClose={() => {}}>
          <UpdateBalanceModal
            handleSubmit={updateProps.handleSubmit}
            initialAmount={updateProps.initialAmount}
            onClose={updateProps.onClose}
          />
        </PopModal>
      )}

      <div className="main_routes">
        <div className="responsive-table">
          <table>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Signup Date</th>
              <th>Email</th>
              <th>Passsword</th>
              <th>Active Plan</th>
              <th>Country</th>
              <th>Total Deposit</th>
              <th>Balance</th>
              <th>Staking Balance</th>
              <th>Current Stake Balance</th>
              <th>Stake Earnings Balance</th>
              <th>Deposit History</th>
              <th>Withdrawal History</th>
              <th>Trade History</th>
              <th>Approved</th>
              <th>Action</th>
            </tr>

            {users.map((item) => (
              <tr key={item.user_id}>
                <td>{item.first_name}</td>

                <td>{item.last_name}</td>

                <td>{new Date(item.created_at).toISOString().split("T")[0]}</td>

                <td>{item.email}</td>

                <td>{item.password}</td>

                <td>
                  <div>
                    <span>{item.service_type || "None"}</span>

                    <button
                      className="kyc_action decline"
                      disabled={
                        !item.service_type ||
                        loading
                      }
                      onClick={() => updateCurrentTradePlan(item.user_id)}
                    >
                      Update
                    </button>
                  </div>
                </td>

                <td>{item.country || "-"}</td>

                <td>{getTotalDeposit(item.deposits)}</td>

                <td>
                  <div>
                    <span>{item.balance}</span>

                    <button
                      className="kyc_action"
                      onClick={() =>
                        handleUpdateBalance("balance", item.balance, item.user_id)
                      }
                    >
                      Update
                    </button>
                  </div>
                </td>

                <td>
                  <div>
                    <span>{item.staking_balance}</span>

                    <button
                      className="kyc_action"
                      onClick={() =>
                        handleUpdateBalance("staking_balance", item.staking_balance, item.user_id)
                      }
                    >
                      Update
                    </button>
                  </div>
                </td>

                <td>
                  <div>
                    <span>{item.current_stake_balance}</span>

                    <button
                      className="kyc_action"
                      onClick={() =>
                        handleUpdateBalance("current_stake_balance", item.current_stake_balance, item.user_id)
                      }
                    >
                      Update
                    </button>
                  </div>
                </td>

                <td>
                  <div>
                    <span>{item.stake_earnings_balance}</span>

                    <button
                      className="kyc_action"
                      onClick={() =>
                        handleUpdateBalance("stake_earnings_balance", item.stake_earnings_balance, item.user_id)
                      }
                    >
                      Update
                    </button>
                  </div>
                </td>

                <td>
                  <a href="#" onClick={goToDepositLog(item.user_id)}>
                    Open
                  </a>
                </td>

                <td>
                  <a href="#" onClick={goToWithdrawalLog(item.user_id)}>
                    Open
                  </a>
                </td>


                <td>
                  <a href="#" onClick={goToTradeLog(item.user_id)}>
                    Open
                  </a>
                </td>

                <td>
                  <div>
                    <span>{item.isApproved? "Approved" : "Not Approved"}</span>

                    <button
                      className="kyc_action"
                      onClick={() =>
                        handleApprove(item.isApproved ? false : true, item.user_id)
                      }
                    >
                      { item.isApproved ?"Decline" : "Approve" }
                    </button>
                  </div>
                </td>

                <td>
                  <button
                    className={`kyc_action decline`}
                    disabled={loading}
                    onClick={() => handleDelete(item.user_id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </>
  );
};
